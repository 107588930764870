.productCard {
  list-style: none;
  font-size: 1.5rem;
  text-align: center;
}

.productCard:hover {
  opacity: 0.8;
}

.productImage {
  border-radius: 10px;
  background-color: black;
}

@media (max-width: 560px) {
  .movieImage {
    width: 90%;
    /* max-height: 530px; */
    height: auto;
  }
}
